@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Spectral by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Icon */

	.icon {
		&.major {
			border: none;

			&:before {
				font-size: 3em;
			}
		}
	}

/* Form */

	label {
		color: _palette(accent7, fg-bold);
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		border: solid 1px _palette(accent7, border);
	}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		border: solid 2px _palette(accent7, border);

		&.special {
			border: 0 !important;
		}
	}

/* Page Wrapper + Menu */

	#menu {
		display: none;
	}

	body.is-menu-visible {
		#menu {
			display: block;
		}
	}

/* Header */

	#header {
		nav {
			> ul {
				> li {
					> a {
						&.menuToggle {
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}

/* Banner + Wrapper (style4) */

	#banner,
	.wrapper.style4 {
		-ms-behavior: url('js/ie/backgroundsize.min.htc');

		&:before {
			display: none;
		}
	}

/* Banner */

	#banner {
		.more {
			height: 4em;

			&:after {
				display: none;
			}
		}
	}

/* Main */

	#main {
		> header {
			-ms-behavior: url('js/ie/backgroundsize.min.htc');

			&:before {
				display: none;
			}
		}
	}